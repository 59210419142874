.itemClassName{
  padding: 0.5rem;
  width: 16.6%;
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;
}

@media (max-width: 1440px) {
  .itemClassName {
    width: 20%;
  }
}

@media (max-width: 1024px) {
  .itemClassName {
    width: 33%;
  }
}

@media (max-width: 600px) {
  .itemClassName {
    width: 100%;
  }
}

.listClassName {
    display: flex;
    flex-wrap: wrap;
}